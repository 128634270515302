* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

@media (max-width: 767px){
  table.responsive {
    width: 100%;
  }

  table.responsive td, table.responsive th {
    border: 0px solid #ddd;
    padding: 5px;
  }

  table.responsive tr:nth-child(even){background-color: #f2f2f2;}

  table.responsive tr:hover {background-color: #ddd;}

  table.responsive th {
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: left;
    background-color: #e0e0e0;
  }
  
  table.responsive thead {
      display: none;
  }

  table.responsive tbody {
      display: block;
  }

  table.responsive td table {
      display: inline-block;
      width: 60%;
      vertical-align: top;
  }

  table.responsive tr {
      display: block;
      margin-bottom: 20px;
      background-color: #ffffff;
      border: 1px solid #e0e0e0;
      border-bottom: 0;
      overflow: hidden;
  }

  table.responsive tr.odd {
      background-color: #ffffff;
  }

  table.responsive tbody td > div {
      vertical-align: middle;
      display: inline-block;
      width: 60%;
      padding-left: 15px;
      position: relative;
    box-sizing: border-box;
  }

  table.responsive tbody td, .views-table.responsive > tbody > tr > td {
      display: block;
      width: 100% !important;
      vertical-align: top;
      border: 0;
      border-bottom: 1px solid #e0e0e0;
    box-sizing: border-box;
  }
  table.responsive tbody tr td:first-child {
      background: #e0e0e0;
  }
  table.responsive tbody td:before {
      content: attr(data-th);
      font-weight: bold;
      display: inline-block;
      width: 40%;
      vertical-align: middle;
      padding-right: 15px;
    box-sizing: border-box;
  }
}